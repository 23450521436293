@import 'platform-colors';

.mdc-linear-progress {
    --mdc-linear-progress-track-color: #{$neutral-light};
    --mdc-linear-progress-active-indicator-color: #{$primary};

    &.green-fill {
        --mdc-linear-progress-active-indicator-color: #{$success};
    }
}
